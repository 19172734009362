import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from '../translate.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(private http: HttpClient, private translateService: TranslateService) { }

  public handleError(error: HttpErrorResponse | any) {
    let errMsg: any;

    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';
      const err = body || JSON.stringify(body);
      errMsg = err.text;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

  public header(lang?: string, token?: string) {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);
    header = header.append('Accept-Language', lang ? lang : 'en')
    if ( token ) {
      header = header.append('Authorization', 'Bearer ' + token);
    }
    return { headers: header };
  }

  getCarMakes(lang?: string, token?: string): Observable<any> {
    return this.http.get<any>( environment.apiUrl + '/api/Catalog/GetMake', this.header(lang, token)).pipe(
      catchError(this.handleError)
    );
  }

  getCarModels( carMake: string, lang?: string, token?: string): Observable<any> {
    return this.http.get<any>( environment.apiUrl + '/api/Catalog/GetModels?Make=' + carMake, this.header(lang, token)).pipe(
      catchError(this.handleError)
    );
  }

  getCarTrim( carMake: string, carModel: string, lang?: string, token?: string): Observable<any> {
    return this.http.get<any>( environment.apiUrl + '/api/Catalog/GetTrim?Make=' + carMake + '&Model=' + carModel, this.header(lang, token)).pipe(
      catchError(this.handleError)
    );
  }

  getPassengerCount( carMake: string, carModel: string, carTrim: string, token?: string): Observable<any> {
    return this.http.get<any>( environment.apiUrl + '/api/Catalog/GetPassengerCount?Make=' + carMake + '&Model=' + carModel + '&Trim=' + carTrim, this.header( null, token )).pipe(
      catchError(this.handleError)
    );
  }
}
