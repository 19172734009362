import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FAQ } from 'src/model/faq';
import { FAQCategory } from 'src/model/faq-category';
import { TranslateService } from '../translate.service';
declare let $: any;

@Component({
  selector: 'app-faqs-assist',
  templateUrl: './faqs-assist.component.html',
  styleUrls: ['./faqs-assist.component.css']
})
export class FaqsAssistComponent implements OnInit {

  openFAQ: HTMLElement;
  FAQCategoryCurrent: FAQCategory;
  tempCategoryFAQ: FAQCategory;
  searchCategoryFAQ: FAQCategory = new FAQCategory();

  faqsroadside: FAQ[] =[];
  contactSAiyarti: FAQ[] =[];

  faqCategoriesFAQ: FAQCategory[] =[];
  search: string;
  
  constructor(private activatedRoute: ActivatedRoute,
    private transalteService: TranslateService) { }

  ngOnInit() {

    this.activatedRoute.queryParamMap.subscribe( param => {
      if ( param.get('lang') ) {
        this.setLang( param.get('lang') );
      }
    }, error => {});

    this.activatedRoute.paramMap.subscribe( param => {
      if ( param.get('lang') ) {
        this.setLang( param.get('lang') );
      }
    }, error => {});

    this.faqsroadsideCOl();

    const roadsideFAQ = new FAQCategory();
    roadsideFAQ.category = 'General';
    roadsideFAQ.categoryAr = 'شروط عامة:';
    roadsideFAQ.faqs = this.faqsroadside;
    this.faqCategoriesFAQ.push(roadsideFAQ);
    this.FAQCategoryCurrent = roadsideFAQ;

    // const ua = navigator.userAgent;

    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
    //   this.FAQCategoryCurrent = undefined;
    // }
  }

  setLang(lang: string) {
    this.transalteService.use(lang);
    $(document).ready(() => {
      $('body').removeClass('en');
      $('body').removeClass('ar');
      $('body').addClass(lang);
    });
  }

  setCurrentCategoryFAQ(category: FAQCategory) {
    this.FAQCategoryCurrent = category;
  }

  searchFAQs(event: string) {
    if (event && event.length > 0) {
      event = event.toLowerCase();

      this.searchCategoryFAQ.faqs = [];
      this.searchCategoryFAQ.category = '';
      this.faqCategoriesFAQ.forEach(element => {
        element.faqs.forEach(faq => {
          const indexOfQuestion = faq.question.toLowerCase().indexOf(event);
          if(indexOfQuestion >= 0) {
            this.searchCategoryFAQ.faqs.push(faq);
          }

          if (indexOfQuestion < 0 && faq.answerHTML.toLowerCase().indexOf(event) >= 0) {
            this.searchCategoryFAQ.faqs.push(faq);
          }
        });
      });


      if (this.FAQCategoryCurrent !== this.searchCategoryFAQ) {
        this.tempCategoryFAQ = this.FAQCategoryCurrent;
        this.FAQCategoryCurrent = this.searchCategoryFAQ;
      }
    } else {
      this.FAQCategoryCurrent = this.tempCategoryFAQ;
    }
    
  }

  open(event) {
    const element = event.srcElement;
    const content = element.nextElementSibling.nextElementSibling;
    const indicator = element.nextElementSibling;
    indicator.style.transition = '.2s';
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
      this.openFAQ = undefined;
      indicator.style.transform = 'rotate(0deg)';
    } else {
      this.close(this.openFAQ);
      this.openFAQ = element;
      indicator.style.transform = 'rotate(180deg)';
      content.style.maxHeight = content.scrollHeight + 'px';
    }
  }
  close(element) {
    if (element) {
      const content = element.nextElementSibling.nextElementSibling;
      const indicator = element.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
        indicator.style.transform = 'rotate(0deg)';
      } 
    }
  }

  private faqsroadsideCOl() {

    const faq1 = new FAQ();
    faq1.question = 'How can I buy roadside assistance?';
    faq1.questionAr = 'كيف يمكنني شراء خدمة المساعدة على الطريق؟';
    faq1.answerHTML = 'When you buy insurance through Saiyarti, you automatically get enrolled in the Saiyarti FAQ program- ensuring you are safeguarded from all angles.';
    faq1.answerHTMLAr = 'عندما تشتري تأمينًا من خلال سيارتي ، يتم تسجيلك تلقائيًا في برنامج سيارتي للمساعدة - مما يضمن لك الحماية من جميع الزوايا';
    this.faqsroadside.push(faq1);

    const faq2 = new FAQ();
    faq2.question = 'How do I contact Saiyarti FAQ?';
    faq2.questionAr = 'كيف أتصل بسيارتي أسيست؟';
    faq2.answerHTML = 'For using your membership services and any roadside assistance we are available at <a href="tel:+965 22275944">+965 22275944</a>. The QR code at the back of membership card is for sending a Whatsapp message to the Saiyarti FAQ number. The service is available 24 x 7 x 365 <br> You may also contact our offices during normal working hours via phone on <a href="tel:+965 22207335">+965 22207335</a> for any additional inquiries. You may also write to us at <a href="mailto:info@saiyarti.com">info@saiyarti.com</a>. We aim to respond within 24 hours on all business days (Sunday-Thursday)';
    faq2.answerHTMLAr = 'لاستخدام خدمات عضويتك وأي مساعدة على الطريق ، نحن متواجدون على <a href="tel:+965 22275944">22275944 965+</a> .رمز الاستجابة السريعة الموجود في الجزء الخلفي من بطاقة العضوية هو لإرسال رسالة واتساب إلى رقم سيارتى اسيست .الخدمة متاحة 24 × 7 × 365 <br> يمكنك أيضًا الاتصال بمكاتبنا أثناء ساعات العمل العادية عبر الهاتف على <a href="tel:+965 22207335">22207335 965+</a> لأي استفسارات إضافية. يمكنك أيضًا مراسلتنا على <a href="mailto:info@saiyarti.com">info@saiyarti.com</a>. نهدف إلى الرد خلال 24 ساعة في جميع أيام العمل (الأحد - الخميس)';
    this.faqsroadside.push(faq2);

    const faq3 = new FAQ();
    faq3.question = 'How does roadside assistance work?';
    faq3.questionAr = 'كيف تعمل المساعدة على الطريق؟';
    faq3.answerHTML = 'Roadside assistance is your go-to source when you’re stuck in an unfortunate circumstance - a tire burst, a battery failure or even an engine failure. When this situation occurs, all you have to do is call or Whatsapp the Saiyarti FAQ number and we will be at your service as soon as possible. Roadside FAQ is a 24 x 7 x 365 service. <br> Our 24-hour emergency program provides services such as: <br> •	Towing <br> •	A jump start <br> •	Gas delivery <br> •	A flat tire change <br> •	Winching';
    faq3.answerHTMLAr = 'المساعدة على الطريق هي المصدر الذي يمكنك الذهاب إليه عندما تكون عالقًا في ظروف مؤسفة - انفجار الإطار أو تعطل البطارية أو حتى تعطل المحرك. عند حدوث هذا الموقف ، كل ما عليك فعله هو الاتصال أو واتساب برقم سيارتي أسيستوسنكون في في خدمتك في أقرب وقت ممكن. خدمة المساعدة على الطريق هي خدمة 24 × 7 × 365. <br> يقدم برنامج الطوارئ لدينا على مدار 24 ساعة خدمات مثل: <br> سحب سيارة <br> القفز فجأة <br> توصيل الوقود <br> تغيير الإطار المثقوب <br> الرفع';
    this.faqsroadside.push(faq3);

    const faq4 = new FAQ();
    faq4.question = 'When does my membership start and end?';
    faq4.questionAr = 'متى تبدأ عضويتي وتنتهي؟';
    faq4.answerHTML = 'Your Saiyarti FAQ membership is linked to the insurance plan purchased through Saiyarti and is valid from and to the dates of your insurance policy.';
    faq4.answerHTMLAr = 'عضوية سيارتي أسيستالخاصة بك مرتبطة بخطة التأمين المشتراة من خلال سيارتي وهي صالحة من وإلى تواريخ وثيقة التأمين الخاصة بك.';
    this.faqsroadside.push(faq4);

    const faq5 = new FAQ();
    faq5.question = 'What is the cancellation policy?';
    faq5.questionAr = 'ما هي سياسة الإلغاء؟';
    faq5.answerHTML = 'If your Saiyarti insurance plan is registered with Moroor and the Daftar is renewed, there are no cancellations allowed. If it has not been registered with Moroor yet, the insurance policy can be cancelled and therefore the Saiyarti FAQ plan will also get cancelled.';
    faq5.answerHTMLAr = 'إذا كانت خطة تأمين سيارتي الخاصة بك مسجلة لدى المرور وتم تجديد الدفتر ، فلا يُسمح بالإلغاء. إذا لم يتم تسجيله لدى المرور حتى الآن ، فيمكن إلغاء وثيقة التأمين وبالتالي سيتم أيضًا إلغاء خطة سيارتي أسيست.';
    this.faqsroadside.push(faq5);

    const faq6 = new FAQ();
    faq6.question = 'Who will tow my car?';
    faq6.questionAr = 'من سوف يسحب سيارتي؟';
    faq6.answerHTML = 'When you call customer service, we will dispatch a servicing agent to your location, and they will handle the on-ground situation from there. Based on our call/Whatsapp, we come equipped with all the necessary items required to repair/fix your car.';
    faq6.answerHTMLAr = 'عندما تتصل بخدمة العملاء ، سوف نرسل وكيل خدمة إلى موقعك ، وسوف يتعاملون مع الموقف على الأرض من هناك. بناءً على مكالمتنا / واتساب ، نأتي مجهزين بجميع العناصر اللازمة لإصلاح / إصلاح سيارتك.';
    this.faqsroadside.push(faq6);

    const faq7 = new FAQ();
    faq7.question = 'What is included in my coverage?';
    faq7.questionAr = 'ما هو مدرج في تغطيتي؟';
    faq7.answerHTML = 'Within your yearly coverage, you can redeem 1 free crane pickup/tow and other unlimited free services like breakdown assistance (mechanical or accidental), battery recharge, tire puncturerelated assistance, emergency fuel, etc. If your policy is for two years, then you can redeem all the services twice, plus 2 crane pickups in your membershipperiod.';
    faq7.answerHTMLAr = 'ضمن تغطيتك السنوية ، يمكنك استرداد خدمة سحب / سحب رافعة مجانية واحدة وغيرها من الخدمات المجانية غير المحدودة مثل المساعدة في حالة الأعطال (الميكانيكية أو العرضية) ، وإعادة شحن البطارية ، والمساعدة المتعلقة بثقب الإطارات ، ووقود الطوارئ ، وما إلى ذلك. إذا كانت وثيقتك لمدة عامين ، ثم يمكنك استرداد جميع الخدمات مرتين ، بالإضافة إلى 2 رافعة في فترة عضويتك.';
    this.faqsroadside.push(faq7);

    const faq8 = new FAQ();
    faq8.question = 'Can I transfer or use the FAQ services on my other car?';
    faq8.questionAr = 'هل يمكنني نقل أو استخدام الخدمات المساعدة في سيارتي الأخرى؟';
    faq8.answerHTML = 'Saiyarti FAQ assistance is tied to your Saiyarti policy. Therefore, only the car which has an insurance policy through Saiyarti is eligible for this offering.';
    faq8.answerHTMLAr = 'سيارتي أسيستالمساعدة مرتبطة بوثيقةسيارتي الخاصة بك. لذلك ، فإن السيارة التي لديها وثيقة تأمين من خلال سيارتي هي فقط المؤهلة لهذا العرض.';
    this.faqsroadside.push(faq8);

    const faq9 = new FAQ();
    faq9.question = 'How long does it take to get roadside assistance?';
    faq9.questionAr = 'كم من الوقت يستغرق الحصول على المساعدة على الطريق؟';
    faq9.answerHTML = 'When you call up/Whatsapp the Saiyarti FAQ number, we will give you an approximate based on your location and where the closest service agent is. Times may vary depending on time of day, and distance.';
    faq9.answerHTMLAr = 'عند الاتصال / واتساب برقم سيارتي أسيست، سنقدم لك تقديرًا تقريبيًا بناءً على موقعك ومكان أقرب وكيل خدمة. قد تختلف الأوقات حسب الوقت من اليوم والمسافة.';
    this.faqsroadside.push(faq9);

    const faq10 = new FAQ();
    faq10.question = 'Will Saiyarti FAQ cover all the cars I own?';
    faq10.questionAr = 'هل تساعد سيارتي أسيستفي تغطية جميع السيارات التي أمتلكها؟';
    faq10.answerHTML = 'Saiyarti FAQ is only valid and can be used for the car that has third party motor insurance policy through Saiyarti.';
    faq10.answerHTMLAr = 'سيارتي أسيستصالحة فقط ويمكن استخدامها للسيارة التي لديها وثيقةتأمين على السيارات ضد الغيرمن خلال سيارتي.';
    this.faqsroadside.push(faq10);

    const faq11 = new FAQ();
    faq11.question = 'How do I renew a Saiyarti FAQ plan?';
    faq11.questionAr = 'كيف أجدد خطة سيارتي أسيست؟';
    faq11.answerHTML = 'Every time you purchase or renew a Saiyarti insurance plan, the Saiyarti FAQ plan gets added/renewed to your policy.';
    faq11.answerHTMLAr = 'في كل مرة تقوم فيها بشراء أو تجديد خطة تأمين سيارتي ، تتم إضافة / تجديد خطة سيارتي أسيستإلى وثيقتك.';
    this.faqsroadside.push(faq11);

    const faq12 = new FAQ();
    faq12.question = 'Can Saiyarti insurance be transferred to a new car when I purchase it?';
    faq12.questionAr = 'هل يمكن نقل تأمين سيارتي إلى سيارة جديدة عند شرائها؟';
    faq12.answerHTML = 'Saiyarti FAQ membership is non-transferable. ';
    faq12.answerHTMLAr =  'عضوية سيارتي أسيستغير قابلة للتحويل';
    this.faqsroadside.push(faq12);

    const faq13 = new FAQ();
    faq13.question = 'Are you able to repair my car on-site, at the time of breakdown/stoppage?';
    faq13.questionAr = 'هل أنت قادر على إصلاح سيارتي في الموقع عند حدوث عطل / توقف؟';
    faq13.answerHTML = 'Whenever it is possible to do minor repairs, we do. We can only determine that when we reach your location and vehicle. Therefore, we do not give you an indication of this when you’re speaking to a Saiyarti FAQ representative. If it cannot be fixed on-site, we will help you tow the car to the nearest service center. All costs of parts and repairs will be borne by the car owner.';
    faq13.answerHTMLAr = 'كلما كان من الممكن إجراء إصلاحات طفيفة ، نقوم بذلك. لا يمكننا تحديد ذلك إلا عندما نصل إلى موقعك ومركبتك. لذلك ، لا نعطيك إشارة إلى ذلك عندما تتحدث إلى ممثلسيارتي أسيست.إذا تعذر إصلاحه في الموقع ، فسنساعدك على سحب السيارة إلى أقرب مركز خدمة. يتحمل مالك السيارة جميع تكاليف قطع الغيار والإصلاحات.';
    this.faqsroadside.push(faq13);

    const faq14 = new FAQ();
    faq14.question = 'Will Saiyarti FAQ be responsible for costs of repairs/replacements?';
    faq14.questionAr = 'هل ستكون سيارتي أسيستمسؤولة عن تكاليف الإصلاح / الاستبدال؟';
    faq14.answerHTML = 'No, Saiyarti FAQ is only a service to ensure you’re never left stranded/stuck with an emergency. All costs will be borne by the car owner at the time of servicing.';
    faq14.answerHTMLAr = 'لا ، سيارتي أسيستهي خدمة فقط لضمان عدم تركك عالقًا / عالقًا في حالة طوارئ. يتحمل مالك السيارة جميع التكاليف وقت تقديم الخدمة.';
    this.faqsroadside.push(faq14);

    const faq15 = new FAQ();
    faq15.question = 'What are the QR codes on the back and front of the card?';
    faq15.questionAr = 'ما هي رموز QR الموجودة على ظهر البطاقة وأمامها؟';
    faq15.answerHTML = 'The QR codes are implemented to make it easy and quick to gather your/your car’s information from our central database. <br> The front QR code is for your Saiyarti policy information, which also means you don’t need to physically carry your documents anymore. <br> The back QR code is for sending a Whatsapp message to the Saiyarti FAQ number. If you’re in a situation where you need to reach out, you can call or Whatsapp us. Additionally, this number is managed 24 x 7 x 365 and will have an extremely quick turnaround time.';
    faq15.answerHTMLAr = 'يتم تطبيق رموز الاستجابة السريعة لتسهيل عملية جمع معلوماتالسيارة/ سيارتك من قاعدة البيانات المركزية الخاصة بنا وسرعة ذلك. <br> رمز الاستجابة السريعة الأمامي مخصص لمعلومات وثيقة سيارتي ، مما يعني أيضًا أنك لست بحاجة إلى حمل مستنداتك فعليًا بعد الآن. <br> رمز الاستجابة السريعة الخلفي هو لإرسال رسالة واتساب إلى رقم سيارتي أسيست. إذا كنت في موقف تحتاج إلى التواصل معه ، يمكنك الاتصال بنا أو عبر واتساب. بالإضافة إلى ذلك ، تتم إدارة هذا الرقم على مدار 24 ساعة طوال أيام الأسبوع وسيكون له وقت استجابة سريع للغاية.';
    this.faqsroadside.push(faq15);

    const faq16 = new FAQ();
    faq16.question = 'I had bought a policy for my bike through Saiyarti, am I eligible for the Saiyarti FAQ program?';
    faq16.questionAr = 'اشتريت وثيقة تأمين لدراجتي من خلال سيارتي ، هل أنا مؤهل لبرنامج سيارتي أسيست؟';
    faq16.answerHTML = 'Saiyarti FAQ is only for 4-wheelers and not for 2-wheelers/bikes. ';
    faq16.answerHTMLAr = 'سيارتي أسيستمخصصة فقط للعجلات الأربع وليس للدراجات ذات العجلتين.';
    this.faqsroadside.push(faq16);

    const faq17 = new FAQ();
    faq17.question = 'Can I get a refund if I have not used any of the FAQ services or for the unused services?';
    faq17.questionAr = 'هل يمكنني استرداد أموالي إذا لم أستخدم أيًا من خدمات المساعدة أو الخدمات غير المستخدمة؟';
    faq17.answerHTML = 'No, you cannot receive money for unused services. Neither can you carry forward the unused services to next year. ';
    faq17.answerHTMLAr = 'لا ، لا يمكنك الحصول على أموال مقابل الخدمات غير المستخدمة. لا يمكنك أيضًا ترحيل الخدمات غير المستخدمة إلى العام المقبل.';
    this.faqsroadside.push(faq17);

    const faq18 = new FAQ();
    faq18.question = 'What is the crane related service?';
    faq18.questionAr = 'ما هي الخدمة المتعلقة بالرافعة؟';
    faq18.answerHTML = 'The crane service is an assistance service which can be used to tow the car to a specific destination in the event of a mechanical/ electrical failure or traffic accident that makes it impossible to start or drive the car';
    faq18.answerHTMLAr = 'خدمة الرافعة هي خدمة مساعدة يمكن استخدامها لسحب السيارة إلى وجهة معينة في حالة حدوث عطل ميكانيكي / كهربائي أو حادث مروري يجعل من المستحيل تشغيل أو قيادة السيارة';
    this.faqsroadside.push(faq18);

    const faq19 = new FAQ();
    faq19.question = 'Do you provide fuel in case my car stopped in the middle of the road?';
    faq19.questionAr = 'هل تزودون بالوقود في حال توقفت سيارتي في منتصف الطريق؟';
    faq19.answerHTML = 'Yes, enough fuel will be provided so that the car can be driven to the nearest petrol station.';
    faq19.answerHTMLAr = 'نعم ، سيتم توفير وقود كافٍ لقيادة السيارة لأقرب محطة وقود.';
    this.faqsroadside.push(faq19);

    const faq20 = new FAQ();
    faq20.question = 'How much is the additional crane service?';
    faq20.questionAr = 'كم هي خدمة الرافعة الإضافية؟';
    faq20.answerHTML = 'Additional crane service charges will be disclosed to the client on a case by case basis. The fee has to be paid before availing the additional service.';
    faq20.answerHTMLAr = 'سيتم الإفصاح عن رسوم خدمة الرافعة الإضافية للعميل على أساس كل حالة على حدة. يجب دفع الرسوم قبل الاستفادة من الخدمة الإضافية.';
    this.faqsroadside.push(faq20);

    const faq21 = new FAQ();
    faq21.question = 'When will I receive my Saiyarti FAQ card?';
    faq21.questionAr = 'متى سأستلم بطاقة سيارتي أسيستالخاصة بي؟';
    faq21.answerHTML = 'The membership card will be delivered to you in 24-48 hours along with your original policy copies';
    faq21.answerHTMLAr = 'سيتم تسليم بطاقة العضوية إليك في غضون 24-48 ساعة مع نسخ الوثيقة الأصلية';
    this.faqsroadside.push(faq21);

    const faq22 = new FAQ();
    faq22.question = 'I lost my card. How do I request a replacement card?';
    faq22.questionAr = 'لقد فقدت بطاقتي. كيف أطلب بطاقة بديلة؟';
    faq22.answerHTML = 'No worries, we can deliver you a new membership card printed with the same details that was mentioned on the lost card. Please contact Saiyarti customer service on <a href="tel:+965 22207335">+965 22207335</a> / <a href="tel:+965 6773411">+965 67734711</a> , or email us on <a href="mailto:info@saiyarti.com">info@saiyarti.com.</a>';
    faq22.answerHTMLAr = 'لا تقلق ، يمكننا أن نسلمك بطاقة عضوية جديدة مطبوعة بنفس التفاصيل المذكورة في البطاقة المفقودة. يرجى الاتصال بخدمة عملاء سيارتي على 22207335 965 + /  67734711 965 + ، أو مراسلتنا على <a href="mailto:info@saiyarti.com">info@saiyarti.com.</a>';
    this.faqsroadside.push(faq22);

    const faq23 = new FAQ();
    faq23.question = 'Will my Saiyarti FAQ validity change if I have done an endorsement for date change on my policy purchased through Saiyarti?';
    faq23.questionAr = 'هل ستتغير صلاحيةسيارتي أسيستإذا قمت بتأييد تغيير التاريخ على وثيقتي المشتراة من خلال سيارتي؟';
    faq23.answerHTML = 'The membership card’s validity follows the policy period. If you have carried out an endorsement of date change, we will change and deliver you a replacement card with the new expiry date.';
    faq23.answerHTMLAr = 'صلاحية بطاقة العضوية تتبع فترة الوثيقة. إذا كنت قد أجريت مصادقة على تغيير التاريخ ، فسنقوم بتغيير بطاقة بديلة ونسلمها لك بتاريخ انتهاء الصلاحية الجديد.';
    this.faqsroadside.push(faq23);
  }

}
