import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as skrollr from 'skrollr';
import { Title } from '@angular/platform-browser';
import { GetInTouchService } from '../get-in-touch.service';
import { Router, NavigationEnd } from '@angular/router';
declare var $: any;


@Component({
  selector: 'app-saiyarti-business',
  templateUrl: './saiyarti-business.component.html',
  styleUrls: ['./saiyarti-business.component.css']
})
export class SaiyartiBusinessComponent implements OnInit, AfterViewInit {
  skrollr: any;
  contactSubmitted = false;
  message: string;
  name: string;
  phoneNumber: number;
  email: string;
  @ViewChild('contactForm', { static: true }) contactForm;

  constructor(private titleService: Title,private getInTouchService: GetInTouchService, router: Router) {  router.events.subscribe((val) => {

    if (val instanceof NavigationEnd) {
      this.contactForm.reset();
      this.contactSubmitted = false;
    }
  });
  this.titleService.setTitle('Saiyarti-Business');
}


  ngOnInit() {
    if ($(window).width() >= 992) {
      this.skrollr = skrollr.init({
        smoothScrolling: false,
        forceHeight: false
      });
    }
  }

  ngAfterViewInit() {
    this.skrollr.refresh();
  }

  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  submitContact(form) {
    const data = {
      email: form.value.email,
      message: form.value.message,
      phoneNumber: form.value.phoneNumber,
      name: form.value.name,
      source: 'Website',
      type: 'Header'
    };
    console.log(form.value);
    this.contactSubmitted = true;
    form.reset();
    setTimeout(() => {
      this.contactSubmitted = false;
    }, 3000);
    this.getInTouchService.getInTouch(data).subscribe(res => { }, err => { });
  }
}
