import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.css']
})
export class MakePaymentComponent implements OnInit {
  phoneNumber: string;
  plateNumber: string;
  civilCardNumber: string;
  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  makePayment(form) {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);
    this.http.post<any>(environment.apiUrl + '/api/account/GetPaymentGatewayUrl',
      {
        amount: 6.00,
        phoneNumber: this.phoneNumber,
        plateNumber: this.plateNumber,
        civilCardNumber: this.civilCardNumber,
        successUrl: 'https://saiyarti.com/payment-success',
        failureUrl: 'https://saiyarti.com/payment-failure'
      }, { headers: header }).subscribe(response => {
        window.location.href = response.paymentGatewayUrl;
      });
  }

}
