import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Featured } from 'src/model/featured';

@Component({
  selector: 'app-featured-on',
  templateUrl: './featured-on.component.html',
  styleUrls: ['./featured-on.component.css']
})
export class FeaturedOnComponent implements OnInit {
  featuredItems: Featured[] = [];
  constructor(private titleService: Title) {
    this.titleService.setTitle('Saiyarti-Press and Media');
    this.addFeatured(
      'assets/image/logo_timeskuwait.png',
      'Insure & secure launches a mobile app “Saiyarti” which is available on both iOS and Android devices for purchasing Motor Third party Insurance policies which is mandatory for the registration of vehicles in the traffic department. Saiyarti is part of the Insure & Secure Group, which has been involved in the field of Insurance for over 40 years.Saiyarti’s fully automated mobile app helps car owners to obtain',
      'شركة إنشور آند سيكيور تطلق تطبيق "سيارتي" للموبايل المتوفر على كل من أجهزة أي أو إس و أندوريد لشراء وثائق تأمين السيارات ضد الغير وهو أمر إلزامي لتسجيل المركبات في إدارة المرور. سيارتي هو جزء من مجموعة إنشور آند سيكيور ، التي شاركت في مجال التأمين لأكثر من 40 عامًا. يساعد تطبيق الهاتف المنصة الالكترونية بالكامل من سيارتي مالكي السيارات في الحصول على .....' ,
      'https://www.timeskuwait.com/news/new-app-for-motor-insurance-launched/'
    );
    this.addFeatured(
      'assets/image/logo_bazaartown.png',
      'Renewing a car license should be a simple affair, but it’s often frustrating and a waste of time, especially if the car is older than three years. When you purchase a new car, it comes with a three-year motor third party insurance. Once the three years are up however, the car can only be insured for a period of one year. Car owners have to visit the insurance company at least once a year to get',
      'يجب أن يكون تجديد رخصة السيارة أمرًا بسيطًا ، ولكنه غالبًا ما يكون محبطًا ومضيعة للوقت ، خاصة إذا كانت السيارة أقدم من ثلاث سنوات. عندما تشتري سيارة جديدة ، فإنها تأتي مع تأمين لمدة ثلاث سنوات ضد الغير. ومع ذلك ، بمجرد انقضاء السنوات الثلاث ، لا يمكن التأمين على السيارة إلا لمدة عام واحد. يجب على مالكي السيارات زيارة شركة التأمين مرة واحدة على الأقل في السنة للحصول على ........ ',
      'https://bazaar.town/saiyarti/'
    );
    this.addFeatured(
      'assets/image/logo_aljarida.png',
      'There is no longer a need to go to the car insurance companies after today, or wait boring and waste time with the presence of the application Saiyarti which was able to make life easier and faster; by doing insurance on the phone to reach the door of your house. Al Jarida met Satish Sharma, owner of the Saiyarti application, which is the first of its kind in Kuwait.',
      'لم تعد هناك حاجة للذهاب إلى شركات التأمين على السيارات بعد اليوم ، أو الانتظار الممل وإضاعة الوقت مع وجود تطبيق سيارتي الذي استطاع أن يجعل الحياة أسهل وأسرع ؛ عن طريق عمل تأمين على الهاتف للوصول إلى باب منزلك. والتقت الجريدة بساتيش شارما صاحب تطبيق سيارتي الأول من نوعه في الكويت....... ',
      'https://www.aljarida.com/articles/1584803868308889000/'
    );
    this.addFeatured(
      'assets/image/logo_q8india.png',
      'Saiyarti is the silver bullet solution to renewing car insurance problem. The app is a brainchild of ‘Insure & Secure’, a group that has been operating in the field of Insurance for over three decades, covering all aspects of the insurance industry including both Life and General policies. It operates independently as a professional Insurance advisory firm. The one-of-a-kind mobile app and web portal makes the process',
      'سيارتي هو الحل الفضي لتجديد مشكلة التأمين على السيارات. التطبيق هو من بنات أفكار "إنشور آند سيكيور" ، وهي مجموعة تعمل في مجال التأمين لأكثر من ثلاثة عقود ، وتغطي جميع جوانب صناعة التأمين بما في ذلك وثائق التأمين على الحياة والتأمينات العامة. تعمل بشكل مستقل كشركة استشارية مهنية للتأمين. تطبيق الهاتف المحمول الفريد من نوعه ومدخل الويب يجعل العملية........ ',
      'https://www.q8india.com/blog/2020/04/07/interview-afsar-ali/'
    );
  }

  addFeatured(image, description, descriptionAr, url) {
    const featured = new Featured();
    featured.image = image;
    featured.description = description;
    featured.descriptionAr = descriptionAr;
    featured.url = url;
    this.featuredItems.push(featured);
  }

  ngOnInit() {
  }

}
