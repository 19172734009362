import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.flipSlider();
  }


  flipSlider() {
    $(document).ready(() => {
      const $viewBox = $('.slide');
      const $boxContainer = $('.slide > .card-container');
      const $box = $('.slide .inlinFlex');
      const boxwidth = $box.outerWidth();
      const boxCount = $box.length;
      const viewBoxWidth = $viewBox.width();
      const viewableBoxCount = parseInt((viewBoxWidth / boxwidth) + '', 10);


      $('#partnerSlideLeft').on('click', () => {
        if ($(':animated').length) {
          return false;
        }
        const leftPoint = parseInt($boxContainer.css('left'), 10);
        console.log(leftPoint + ' < ' + ((boxCount - viewableBoxCount) * boxwidth));
        console.log(leftPoint);
        if (document.body.className.split(' ').indexOf('en') > -1) {
          if (leftPoint >= -1100) {
            $boxContainer.animate({ left: '-=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        } else {
          if (leftPoint > 0) {
            $boxContainer.animate({ left: '-=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        }
      });

      $('#partnerSlideRight').on('click', () => {
        if ($(':animated').length) {
          return false;
        }
        const leftPoint = parseInt($boxContainer.css('left'), 10);
        console.log(boxwidth + leftPoint);
        if (document.body.className.split(' ').indexOf('en') > -1) {
          if ((boxwidth + leftPoint) <= 0) {
            $boxContainer.animate({ left: '+=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        } else {
          if ((boxwidth + leftPoint) <= 1400) {
            $boxContainer.animate({ left: '+=' + boxwidth + 'px' });
            // $("#slideLeft").show();
          } else {
            // $("#slideRight").hide();
          }
        }
      });

    });
  }

}
