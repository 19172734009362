import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { Fee } from 'src/model/fee';

@Injectable({
  providedIn: 'root'
})
export class TPLService {

  private isOgProcess: boolean
  get IsOgProgess() {
    return this.isOgProcess;
  }
  set IsOgProgess(value) {
    this.isOgProcess = value;
    if ( this.isOgProcess ) {
      document.body.classList.add("og-payment");
    } else {
      document.body.classList.remove("og-payment");
    }
  }

  constructor(private http: HttpClient) {}

  public handleError(error: HttpErrorResponse | any) {
    let errMsg: any;

    if (error instanceof HttpErrorResponse) {
      const body = error.error || '';
      const err = body || JSON.stringify(body);
      errMsg = err.text;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }


  public header(token: string) {

    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header = header.append('apiKey', environment.apiKey);
    header = header.append('apiPassword', environment.apiPassword);
    if ( token ) {
      header = header.append('Authorization', 'Bearer ' + token);
    }
    return { headers: header };
  }


  getTplFees(modelYear: number, vehicleType: number, passengers: number, load: number, token: string): Observable<[Fee]> {
    return this.http.get<[Fee]>(environment.apiUrl + '/api/TPL?modelYear=' + modelYear
      + '&vehicleType=' + vehicleType
      + '&passengers=' + passengers
      + '&load=' + load, this.header(token)).pipe(catchError(this.handleError));
  }

  getFees(token: string): Observable<Fee> {
    return this.http.get<Fee>(environment.apiUrl + '/api/Fee', this.header(token)).pipe(catchError(this.handleError));
  }

  completeTransaction(data: any, token: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/api/TPLPurchase', data, this.header(token)).pipe(catchError(this.handleError));
  }

  getPaymentGatewayLink(data: any): any {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', '*/*');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accessCode', environment.paymetGateway.accessCode);
    return this.http.post(environment.paymetGateway.url + '/checkout', { data }, { headers, responseType: 'text' }).pipe(catchError(this.handleError));
  }

  initiateTransaction(renewCustomerId: number, data: any, token: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/api/TPLPurchase/InitiateTransaction/' + renewCustomerId, data, this.header(token)).pipe(catchError(this.handleError));
  }
}
