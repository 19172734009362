import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TPLService } from '../tpl.service';

@Component({
  selector: 'app-details-updated',
  templateUrl: './details-updated.component.html',
  styleUrls: ['./details-updated.component.css']
})
export class DetailsUpdatedComponent implements OnInit, OnDestroy {

  downloadLink: string;

  constructor( private activatedRoute: ActivatedRoute, private tplService: TPLService ) {
  }

  ngOnDestroy(): void {
    this.tplService.IsOgProgess = false;
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe( queryParam => {
      let token = queryParam.get("token");
      let isOg = queryParam.get("isOg");
      if ( token && isOg != "true" ) {
        this.downloadLink = environment.dynamicLink + "?link=https://saiyarti.com/set-password?token=" + token + "&apn=com.rc.saiyarti&ibi=com.saiyarti&isi=1459650287&efr=1&ofl=" + environment.dynamicLinkOfl + "set-password?token=" + token;
      }

      if ( isOg == "true" ) {
        this.tplService.IsOgProgess = true;
      } else {
        this.tplService.IsOgProgess = false;
      }
    }, error => {});
  }

}
