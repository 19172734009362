import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  HostListener,
  ElementRef,
} from "@angular/core";
import { OwlCarousel } from "ngx-owl-carousel";
import { Testimonial } from "../../model/testimonial";
import { Title } from "@angular/platform-browser";
import { DialogServiceService } from "../dialog-service.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { GetInTouchService } from "../get-in-touch.service";
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
  // animations: [
  //   trigger('fade',
  //     [
  //       state('void', style({ opacity: 0 })),
  //       transition(':enter', [animate(300)]),
  //       transition(':leave', [animate(500)]),
  //     ]
  //   )]
})
export class HomePageComponent implements OnInit {
  constructor(
    private titleService: Title,
    private dialogService: DialogServiceService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.titleService.setTitle("Saiyarti - Buy Car Insurance Online");
  }
  scrollToTop() {
    document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
  }

  @ViewChild("reviewsOwlCarousel", { static: true }) owlElement: OwlCarousel;
  @ViewChild("downloadAppModal", { static: true }) downloadAppModal: any;
  index = 0;
  testimonials: Testimonial[] = [];
  userReviews: Testimonial[] = [];
  info = [];
  reviewsNext = true;
  reviewsPrevious = false;

  FeatruedOnOptions = {
    nav: false,
    dots: false,
    loop: true,
    pagination: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        dots: true,
        loop: true,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 4,
      },
      1500: {
        items: 3,
      },
    },
  };

  carouselOptions = {
    nav: false,
    dots: false,
    loop: true,
    pagination: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
        autoplay: true,
        autoplaySpeed: 5000,
        slideTransition: "linear",
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1500: {
        items: 3,
      },
    },
  };

  UserReviewOptions = {
    nav: false,
    dots: false,
    loop: true,
    pagination: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        dots: true,
        loop: true,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1500: {
        items: 3,
      },
    },
  };
  ExeOptions = {
    nav: false,
    dots: false,
    loop: true,
    pagination: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        dots: false,
        loop: true,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
        loop: false,
      },
      1500: {
        items: 3,
        loop: false,
      },
    },
  };

  images = [
    {
      Fimage: "assets/image/logo_aljarida.png",
      url: "https://www.aljarida.com/articles/1584803868308889000/",
    },
    {
      Fimage: "assets/image/logo_bazaartown.png",
      url: "https://bazaar.town/saiyarti/",
    },
    {
      Fimage: "assets/image/logo_association_timeskuwait.png",
      url: "https://www.timeskuwait.com/news/new-app-for-motor-insurance-launched/",
    },
    {
      Fimage: "assets/image/logo_q8india.png",
      url: "https://www.q8india.com/blog/2020/04/06/saiyarti-buying-renewing-motor-third-party-liability-insurance-made-easy/",
    },
  ];

  playVideo() {
    if (document.body.className.split(" ").indexOf("en") > -1) {
      this.dialogService.videoPlayDialog(
        "https://saiyartiproduction.blob.core.windows.net/assets/saiyarti_introduction.mp4"
      );
    } else {
      this.dialogService.videoPlayDialog(
        "https://saiyartiproduction.blob.core.windows.net/assets/saiyarti_introduction_arabic.mp4"
      );
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(
      (queryParam) => {
        let showPopUp: boolean = queryParam.get("showPopup") == "true";
        if (showPopUp && this.downloadAppModal) {
          this.downloadAppModal.nativeElement.click();
        }
      },
      (error) => {}
    );

    const userReview1 = new Testimonial();
    userReview1.img = "/assets/image/ic_userreview.png";
    userReview1.userName = "Sunny Bhatia";
    userReview1.userNameAr = "صني بهاتيا";
    userReview1.StarImg = "/assets/image/ic_reviewstar.png";
    userReview1.review =
      "Extremely relevant in the times of social distancing! In these times I was able to renew my car insurance with a few clicks and a couple of documents uploaded and got the policy delivered to home!";
    userReview1.reviewAr =
      "كنت وثيق الصلة للغاية في أوقات التباعد الاجتماعي! في هذه الأوقات تمكنت من تجديد تأمين سيارتي ببضع ضغطات وبضع مستندات تم تحميلها واستلمت الوثيقة في المنزل!";

    this.userReviews.push(userReview1);

    const userReview2 = new Testimonial();
    userReview2.img = "/assets/image/ic_userreview.png";
    userReview2.userName = "Afsar Ali";
    userReview2.userNameAr = "أفسر علي";
    userReview2.StarImg = "/assets/image/ic_reviewstar.png";
    userReview2.review =
      "Excellent App. User friendly and very very simple to use.";
    userReview2.reviewAr = ".تطبيق ممتاز. سهل الاستخدام للغاية ";
    this.userReviews.push(userReview2);

    const userReview3 = new Testimonial();
    userReview3.img = "/assets/image/ic_userreview.png";
    userReview3.userName = "Ramesh Pandita";
    userReview3.userNameAr = "راميش بانديتا ";
    userReview3.StarImg = "/assets/image/ic_reviewstar.png";
    userReview3.review =
      "Awesome App, Seamless navigation very friendly. Highly recommended.";
    userReview3.reviewAr =
      "تطبيق رائع. التنقل سلس و سهل للغاية ، بالتأكيد سأوصي به لأصدقائي. ";
    this.userReviews.push(userReview3);

    const userReview4 = new Testimonial();
    userReview4.img = "/assets/image/ic_userreview.png";
    userReview4.userName = " Karthikeyan Mani";
    userReview4.userNameAr = " كارتيكيان ماني ";
    userReview4.StarImg = "/assets/image/ic_reviewstar.png";
    userReview4.review =
      "Pre sales support is very good. Product explanation was satisfactory. A nice experience overall. I will surely recommend.";
    userReview4.reviewAr =
      "دعم ما قبل البيع جيد جدا. شرح المنتج كان مرضيا. تجربة جميلة بشكل عام. سأوصي بالتأكيد";
    this.userReviews.push(userReview4);

    const userReview5 = new Testimonial();
    userReview5.img = "/assets/image/ic_userreview.png";
    userReview5.userName = "Saud Iqbal";
    userReview5.userNameAr = "سعود إقبال";
    userReview5.StarImg = "/assets/image/ic_reviewstar.png";
    userReview5.review =
      "The experience of using the sayarti App to renew my car's insurance was flawless. It was so very simple.";
    userReview5.reviewAr =
      "كانت تجربة استخدام تطبيق سيارتي لتجديد تأمين سيارتي خالية من العيوب. لقد كان بسيطا جدا. ";
    this.userReviews.push(userReview5);

    const userReview6 = new Testimonial();
    userReview6.img = "/assets/image/ic_userreview.png";
    userReview6.userName = "Elu Edu";
    userReview6.userNameAr = "اليو  اديو";
    userReview6.StarImg = "/assets/image/ic_reviewstar.png";
    userReview6.review =
      "Unbelievably quick, simple and effective. Got message from ministry saying my car insurance was about to run out. I downloaded the App, submitted the application, and received the new insurance at my door within hours. Most impressive.";
    userReview6.reviewAr =
      "سريع وبسيط وفعال بشكل لا يصدق. تلقيت رسالة من الوزارة تفيد بأن تأمين سيارتي على وشك النفاد. لقد قمت بتنزيل التطبيق ، وقدمت الطلب ، وحصلت على التأمين الجديد على باب منزلي في غضون ساعات. الأمر الأكثر إثارة للإعجاب";
    this.userReviews.push(userReview6);

    const userReview7 = new Testimonial();
    userReview7.img = "/assets/image/ic_userreview.png";
    userReview7.userName = "Ratna Prasad";
    userReview7.userNameAr = "راتانا براساد";
    userReview7.StarImg = "/assets/image/ic_reviewstar.png";
    userReview7.review =
      "Very professional, helpful and prompt response... highly recommended..";
    userReview7.reviewAr =
      "استجابة احترافية للغاية ومفيدة وسريعة ... موصى به للغاية ....";
    this.userReviews.push(userReview7);

    const userReview8 = new Testimonial();
    userReview8.img = "/assets/image/ic_userreview.png";
    userReview8.userName = "Tree Netra";
    userReview8.userNameAr = "ترى نيترا";
    userReview8.StarImg = "/assets/image/ic_reviewstar.png";
    userReview8.review =
      "Insurance Renewal from the comfort of home! Enjoyed their very professional, prompt & customer friendly services.";
    userReview8.reviewAr =
      ".استمتع بخدماتهم المحترفة والسريعة والودية للعملاء !جدد تأمينك من منزلك وانت مستريح ";
    this.userReviews.push(userReview8);

    const userReview9 = new Testimonial();
    userReview9.img = "/assets/image/ic_userreview.png";
    userReview9.userName = "Muthumanickam Rajamanickam";
    userReview9.userNameAr = "موتامانايجم رجامانى كام";
    userReview9.StarImg = "/assets/image/ic_reviewstar.png";
    userReview9.review =
      "Proactive customer care and insurance papers delivered at your doorsteps in 3 hrs with surprise gift voucher. They take care of our need while we spend our valuable time with our near and dear. Just try once and you will feel the difference";
    userReview9.reviewAr =
      "رعاية العملاء الاستباقية وأوراق التأمين يتم تسليمها على عتبة داركم خلال 3 ساعات مع قسيمة هدايا مفاجئة. إنهم يهتمون باحتياجاتنا بينما نقضي وقتنا الثمين مع اقربائنا  وأعزائنا. حاول مرة واحدة وستشعر بالفرق..";
    this.userReviews.push(userReview9);

    const userReview10 = new Testimonial();
    userReview10.img = "/assets/image/ic_userreview.png";
    userReview10.userName = "Hasham";
    userReview10.userNameAr = "هاشم";
    userReview10.StarImg = "/assets/image/ic_reviewstar.png";
    userReview10.review =
      "Have used their application for the first time. Very impressed with the way it has functioned and notifications being sent promptly. Received the policy at my door step with extreme ease. In addition the value added service that comes along with it will surely be useful in the time of distress and when on the road.";
    userReview10.reviewAr =
      "استخدموا تطبيقهم لأول مرة. معجب جدًا بالطريقة التي يعمل بها ويتم إرسال الإشعارات على الفور. استلمت البوليصة امام البيت بكل سهولة. بالإضافة إلى ذلك ، فإن الخدمة  المضافة التي تأتي معها ستكون بالتأكيد مفيدة في وقت الشدة وعندما تكون على الطريق.";
    this.userReviews.push(userReview10);

    const userReview12 = new Testimonial();
    userReview12.img = "/assets/image/ic_userreview.png";
    userReview12.userName = "LV";
    userReview12.userNameAr = "ال فى";
    userReview12.StarImg = "/assets/image/ic_reviewstar.png";
    userReview12.review =
      "I have used this app,will recommend everyone to try this app atleast ones.Support team is excellent and process is hassle free. Keep up your good work";
    userReview12.reviewAr =
      "لقد استخدمت هذا التطبيق ، وسأوصي الجميع بتجربة هذا التطبيق على الأقل. فريق الدعم ممتاز والعملية خالية من المتاعب. تابع عملك الجيد";
    this.userReviews.push(userReview12);

    const userReview13 = new Testimonial();
    userReview13.img = "/assets/image/ic_userreview.png";
    userReview13.userName = "Karim Mukadam";
    userReview13.userNameAr = "كريم مقدام";
    userReview13.StarImg = "/assets/image/ic_reviewstar.png";
    userReview13.review =
      "Very nice app also the sarvices. i just registered my car onto their app and requested for insurance renewal and within 30 minute i received a call from call center, i had to get urgent insurance paper and within hour they delivered at my home";
    userReview13.reviewAr =
      "التطبيق جميل جدا أيضا الخدمات. لقد سجلت سيارتي للتو في التطبيق الخاص بهم وطلبت تجديد التأمين وفي غضون 30 دقيقة تلقيت مكالمة من مركز الاتصال ، وحصلت على اوراق  التأمين عاجلا وفي غضون ساعة تم تسليمها في منزلي";
    this.userReviews.push(userReview13);

    const userReview14 = new Testimonial();
    userReview14.img = "/assets/image/ic_userreview.png";
    userReview14.userName = "meena maini";
    userReview14.userNameAr = "مينا مايني";
    userReview14.StarImg = "/assets/image/ic_reviewstar.png";
    userReview14.review = "Excellent service recommended 👌 👍 👏 😀";
    userReview14.reviewAr = "يوصى بخدمة ممتازة";
    this.userReviews.push(userReview14);

    const userReview15 = new Testimonial();
    userReview15.img = "/assets/image/ic_userreview.png";
    userReview15.userName = "NYC";
    userReview15.userNameAr = "ان واى سى";
    userReview15.StarImg = "/assets/image/ic_reviewstar.png";
    userReview15.review =
      "Very convenient and makes life easy to renew insurance. when I last renewed by third party insurance the renewal policy was delivered to my address the following day. Excellent service. Regular reminders is a added benefit. Thanks Insure and Secure";
    userReview15.reviewAr =
      "مريح للغاية ويسهل تجديد التأمين.  عندما قمت بالتجديد آخر مرة تأمين ضد الغير   ، تم تسليم بوليصة التجديد إلى عنواني في اليوم التالي.  خدمة ممتازة.  التذكير المنتظم هو فائدة إضافية. انشور سكيور";
    this.userReviews.push(userReview15);

    const userReview16 = new Testimonial();
    userReview16.img = "/assets/image/ic_userreview.png";
    userReview16.userName = "Fadi";
    userReview16.userNameAr = "فاديي";
    userReview16.StarImg = "/assets/image/ic_reviewstar.png";
    userReview16.review = "Wonderful application, save time and effort";
    userReview16.reviewAr = "تطبيق عجيب وفر علي وقت وجهد";
    this.userReviews.push(userReview16);

    const userReview17 = new Testimonial();
    userReview17.img = "/assets/image/ic_userreview.png";
    userReview17.userName = "Gopi";
    userReview17.userNameAr = "جوبى";
    userReview17.StarImg = "/assets/image/ic_reviewstar.png";
    userReview17.review =
      "The most convenient app to buy our motor third party policy";
    userReview17.reviewAr =
      "التطبيق الأكثر ملاءمة لشراء وثيقة تامين ضد الغير  للسيارات";
    this.userReviews.push(userReview17);

    const userReview18 = new Testimonial();
    userReview18.img = "/assets/image/ic_userreview.png";
    userReview18.userName = "RK_Kwt";
    userReview18.userNameAr = "ار كى _ كى دبليو تى";
    userReview18.StarImg = "/assets/image/ic_reviewstar.png";
    userReview18.review =
      "Brilliantly designed easy to operate user friendly App combined with excellent service. Wud highly recommend to download and use. Save on time and all unnecessary hassles for renewal of third party vehicle insurance👍";
    userReview18.reviewAr =
      "مصمم ببراعة وسهل التشغيل والتطبيق سهل الاستخدام بالإضافة إلى الخدمة الممتازة.  ينصح  بشدة التنزيل والاستخدام.  وفر في الوقت وجميع المتاعب غير الضرورية لتجديد تأمين المركبات ضد الغير 👍";
    this.userReviews.push(userReview18);

    const userReview19 = new Testimonial();
    userReview19.img = "/assets/image/ic_userreview.png";
    userReview19.userName = "Vikas Samota";
    userReview19.userNameAr = "فيكاس ساموتا";
    userReview19.StarImg = "/assets/image/ic_reviewstar.png";
    userReview19.review =
      "It’s been delivered within 24 hours into my hand...excellent service.";
    userReview19.reviewAr =
      "تم تسليمها في غضون 24 ساعة في يدي ... خدمة ممتازة.";
    this.userReviews.push(userReview19);

    const userReview21 = new Testimonial();
    userReview21.img = "/assets/image/ic_userreview.png";
    userReview21.userName = "Shafzeen";
    userReview21.userNameAr = "شافزين";
    userReview21.StarImg = "/assets/image/ic_reviewstar.png";
    userReview21.review =
      "Exceptional customer service. Ms. Vidya really went out of her way to ensure I got the policy the very same day - that too when they were facing technical difficulties on their server.";
    userReview21.reviewAr =
      "خدمة عملاء استثنائية.  بذلت السيدة فيديا قصارى جهدها للتأكد من أنني حصلت على وثيقة التامين في نفس اليوم - وذلك أيضًا عندما كانوا يواجهون صعوبات فنية على الخادم الخاص بهم.";
    this.userReviews.push(userReview21);

    const userReview22 = new Testimonial();
    userReview22.img = "/assets/image/ic_userreview.png";
    userReview22.userName = "Shiv Bhaduri";
    userReview22.userNameAr = "شيف بادورى";
    userReview22.StarImg = "/assets/image/ic_reviewstar.png";
    userReview22.review =
      "Couldn’t be more happier with this app, easy to use and saves so much of effort with car policy, true value add";
    userReview22.reviewAr =
      "انا سعيد للغاية مع هذا التطبيق ، فهو سهل الاستخدام ويوفر الكثير من الجهد مع وثيقة السيارة أضاف قيمة حقيقية";
    this.userReviews.push(userReview22);

    const userReview23 = new Testimonial();
    userReview23.img = "/assets/image/ic_userreview.png";
    userReview23.userName = "Captainosama";
    userReview23.userNameAr = "كابتن أسامه";
    userReview23.StarImg = "/assets/image/ic_reviewstar.png";
    userReview23.review =
      "Excellent service and generous offers mean a good deal for us";
    userReview23.reviewAr = "خدمة متميزة ، و عروض سخية ، يعني صفقة رابحة لنا";
    this.userReviews.push(userReview23);

    const userReview24 = new Testimonial();
    userReview24.img = "/assets/image/ic_userreview.png";
    userReview24.userName = "Royden Fernandes";
    userReview24.userNameAr = "رويدن فرنانديز";
    userReview24.StarImg = "/assets/image/ic_reviewstar.png";
    userReview24.review =
      "Smooth and hassle free process for profile registration and insurance policy purchase or renewal. Saves time and also gets the papers delivered to your doorstep. Would recommend the app to other users for sure.";
    userReview24.reviewAr =
      "عملية سلسة ولا تشوبها أي عقبات لتسجيل الملف الشخصي وشراء أو تجديد بوليصة التأمين. يوفر الوقت وتحصل أيضًا على الأوراق إلى عتبة داركم. وإنني أوصي بالتطبيق للمستخدمين الآخرين بالتأكيد.";
    this.userReviews.push(userReview24);

    const userReview25 = new Testimonial();
    userReview25.img = "/assets/image/ic_userreview.png";
    userReview25.userName = "Shahul Hameed";
    userReview25.userNameAr = "شاهول حميد";
    userReview25.StarImg = "/assets/image/ic_reviewstar.png";
    userReview25.review =
      "Excellent service during this pandemic situation. Just pay online for your car registration renewal & One year free rode side Assistant card .It will be delivered in to your home. Thanks for the great service.";
    userReview25.reviewAr =
      "خدمة ممتازة خلال وضع الوباء الحالي. ما عليك سوى الدفع عبر الإنترنت لتجديد تسجيل سيارتك والحصول على بطاقة مساعد ركب مجانية لمدة عام واحد، وسيتم تسليمها إلى منزلك. شكرا للخدمة كبيرة.";
    this.userReviews.push(userReview25);

    const userReview26 = new Testimonial();
    userReview26.img = "/assets/image/ic_userreview.png";
    userReview26.userName = "Dharmasri";
    userReview26.userNameAr = "ضرماسرى";
    userReview26.StarImg = "/assets/image/ic_reviewstar.png";
    userReview26.review =
      "Very happy with the convenience and the systematic way of vehicle insurance renewal made by Saiyarti Company and wishing all the best to do a yeoman service to the customers in the years to come. All the best.";
    userReview26.reviewAr =
      'إنه لأستبشر جدًا ياليُسر والطريقة المنهجية لتجديد تأمين السيارات على النحو الذي تُقدمه شركة سيارتي وأتمنى كل التوفيق لتقديم خدمة "يومن" للعملاء في السنوات القادمة. وتفضلوا بقبول وافر الاحترام.';
    this.userReviews.push(userReview26);

    const userReview27 = new Testimonial();
    userReview27.img = "/assets/image/ic_userreview.png";
    userReview27.userName = "Mr. Hussain Aref Bourgi";
    userReview27.userNameAr = "ضرماسرى";
    userReview27.StarImg = "/assets/image/ic_reviewstar.png";
    userReview27.review =
      "Saiyarti service is a very distinctive and fast service and the team responds quickly and effectively.  The delivery service is very fast with punctuality and elegance. I recommend using the car application service to renew the vehicle document and obtain Saiyarti card roadside assistance service.";
    userReview27.reviewAr =
      "خدمة سيارتي خدمة مميزة جدا و سريعة و فريق العمل يتجاوب بشكل سريع  و فعال. خدمة التوصيل سريعة جدا, مع دقة في المواعيد. انا انصح باستخدام خدمة تطبيق سيارتي لتجديد وثيقة المركبات و الحصول على بطاقة سيارتي لخدمة المساعدة على الطريق ";
    this.userReviews.push(userReview27);

    let testimonial = new Testimonial();
    testimonial.img = "/assets/image/ic_userreview.png";
    testimonial.userName = "Afsar Ali";
    testimonial.userNameAr = "السيد حسين عارف بورقي";
    testimonial.StarImg = "/assets/image/ic_reviewstar.png";
    testimonial.review =
      "Excellent App. User friendly and very very simple to use.";
    testimonial.reviewAr = "تطبيق ممتاز. سهل الاستخدام للغاية.v ";
    testimonial.exeImg = "/assets/image/ic_partners.png";
    testimonial.exeNumber = "14+";
    testimonial.exePosition = "Partner Companies";

    this.testimonials.push(testimonial);

    testimonial = new Testimonial();
    testimonial.img = "/assets/image/ic_userreview.png";
    testimonial.userName = "Nico Mage";
    testimonial.StarImg = "/assets/image/ic_reviewstar.png";
    testimonial.review =
      "Excellent App. User friendly and very very simple to use.";
    testimonial.exeImg = "/assets/image/ic_policies.png";
    testimonial.exeNumber = "3k+";
    testimonial.exePosition = "Insurance Delivered";

    this.testimonials.push(testimonial);

    testimonial = new Testimonial();
    testimonial.img = "/assets/image/ic_userreview.png";
    testimonial.userName = "Aftar Ali";
    testimonial.StarImg = "/assets/image/ic_reviewstar.png";
    testimonial.review =
      "Excellent App. User friendly and very very simple to use.";
    testimonial.exeImg = "/assets/image/ic_customers.png";
    testimonial.exeNumber = "4200+";
    testimonial.exePosition = "customers";

    this.testimonials.push(testimonial);
  }
}
