import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/model/customer';
import { Fee } from 'src/model/fee';
import { PurposeOfLicense } from 'src/model/purposeOfLicense';
import { purposeOfLicenseAr } from 'src/model/purposeOfLicenseAr';
import { TPLService } from '../tpl.service';
import { AccountService } from '../account.service';
import * as HesabeCrypt from 'hesabe-crypt';
import * as aesjs from 'aes-js';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '../translate.service';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-policy-detail',
  templateUrl: './policy-detail.component.html',
  styleUrls: ['./policy-detail.component.css']
})
export class PolicyDetailComponent implements OnInit {

  customer: Customer;
  fees: [Fee];
  selectedFee: Fee;
  fee: Fee;
  selectedLicense: PurposeOfLicense;
  get SelectedLicense() {
    return this.selectedLicense;
  }
  set SelectedLicense( value ) {
    this.selectedLicense = value;
    if ( value ) {
      this.getQuote();
    }
  }
  selectedLicenseAr: purposeOfLicenseAr;
  get SelectedLicenseAr() {
    return this.selectedLicenseAr;
  }
  set SelectedLicenseAr( value ) {
    this.selectedLicenseAr = value;
    if ( value ) {
      this.getQuote();
    }
  }
  passengers = 4;
  get Passenger() {
    return this.passengers;
  }
  set Passenger( value ) {
    this.passengers = value;
    if ( value ) {
      this.getQuote();
    }
  }
  load = 0;
  hasQuote = false;
  deliveryInstructions: string;
  deliveryAddress: string;
  payment: HesabeCrypt;
  hasPaymentSuccess: boolean;
  hasPaymentFailure: boolean;
  renewCustomerId: number;
  token: string;
  getQuoteRef: MatSnackBarRef<SimpleSnackBar>;
  purposeOfLicenses =
    [
      new PurposeOfLicense(0, 'Private'),
      new PurposeOfLicense(1, 'Taxi'),
      new PurposeOfLicense(2, 'Japanese Pick Up'),
      new PurposeOfLicense(3, 'American Pick Up'),
      new PurposeOfLicense(4, 'Water Tank'),
      new PurposeOfLicense(5, 'Ambulance'),
      new PurposeOfLicense(6, 'Motorcycle'),
      new PurposeOfLicense(7, 'Crane'),
      new PurposeOfLicense(8, 'Bus'),
      new PurposeOfLicense(12, 'Mixer'),
      new PurposeOfLicense(13, 'Fork Cliff'),
      new PurposeOfLicense(15, 'Tipper'),
      new PurposeOfLicense(18, 'Half Lorry'),
    ];

  purposeOfLicensesAr =
    [
      new purposeOfLicenseAr(0, 'خاصة'),
      new purposeOfLicenseAr(0, 'تاكسي'),
      new purposeOfLicenseAr(0, 'ونيت ياباني'),
      new purposeOfLicenseAr(0, 'ونيت أمريكي'),
      new purposeOfLicenseAr(0, 'خزان مياه'),
      new purposeOfLicenseAr(0, 'إسعاف'),
      new purposeOfLicenseAr(0, ' دراجة بخارية'),
      new purposeOfLicenseAr(0, 'رافعة (كرين)'),
      new purposeOfLicenseAr(0, 'باص'),
    ];

  constructor(private tplService: TPLService, private activatedRoute: ActivatedRoute, private accountService: AccountService,
    private translateService: TranslateService, private snackBox: MatSnackBar) {
    this.customer = new Customer();

    this.selectedLicense = this.purposeOfLicenses[0];
    this.selectedLicenseAr = this.purposeOfLicensesAr[0];

    const secret = environment.paymetGateway.secret;
    const ivKey = environment.paymetGateway.ivKey;

    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    this.payment = new HesabeCrypt(key, iv);

    activatedRoute.queryParams.subscribe(
      params => {
        if (params.id) {
          this.renewCustomerId = +params.id;
        }

        if (params.token) {
          this.token = params.token;
        }

        if (this.renewCustomerId && this.token) {
          this.accountService.getRenewCustomer(params.id, params.token).subscribe(res => {
            this.customer = res;

            if ( !this.hasPaymentSuccess ) {
              this.getQuote();
            }
          });
          this.tplService.getFees(this.token).subscribe(fee => {
            this.fee = fee;
          });
        }
      }
    );
  }

  ngOnInit() {
    document.body.classList.add( 'tpl-payment' );
  }

  ngOnDestroy() {
    document.body.classList.remove( 'tpl-payment' );
  }

  getQuote() {
    if ( this.customer.modelYear != null 
      && this.selectedLicense.id != null 
      && this.passengers != null 
      && this.load != null 
      && this.token != null ) {
      this.hasQuote = false;
      this.tplService.getTplFees(this.customer.modelYear,  ( this.translateService.lang == 'en' ? this.selectedLicense.id : this.selectedLicenseAr.id ), this.passengers, this.load, this.token).subscribe(fees => {
        this.fees = fees;
        this.selectedFee = fees[0];
        this.hasQuote = true;
      }, error => {
        this.getQuoteRef =  this.snackBox.open( "Unable to retrieve payment information.", "Try again", { duration: 2000, horizontalPosition: 'right' } );

        this.getQuoteRef.onAction().pipe( take(1) ).subscribe( action => {
          this.getQuoteRef.dismiss();
          this.getQuote();
        }, error => {});
      });
    }
  }

  makePayment(form) {
    this.tplService.initiateTransaction(this.renewCustomerId,
      {
        passengers: this.passengers,
        load: this.load,
        tplId: this.selectedFee.id,
        purposeOfLicense: this.selectedLicense.name,
        purposeOfLicenseId: this.selectedLicense.id,
        basePremium: this.selectedFee.price,
        amountPaid: this.selectedFee.price + this.getSupervisionFeeForPeriod( this.selectedFee.period ) + this.fee.issueFee + this.fee.deliveryFee + this.fee.convenienceFee,
        supervisionFee: this.getSupervisionFeeForPeriod( this.selectedFee.period ),
        issueFee: this.fee.issueFee,
        deliveryFee: this.fee.deliveryFee,
        convenienceFee: this.fee.convenienceFee
      }, this.token).subscribe(res => {
        // this.accountService.exchangeToken(this.token).subscribe(tokenRes => {

        const request = {
          merchantCode: environment.paymetGateway.merchantCode,
          amount: this.selectedFee.price + this.getSupervisionFeeForPeriod( this.selectedFee.period ) + this.fee.issueFee + this.fee.deliveryFee + this.fee.convenienceFee,
          paymentType: 1,
          responseUrl: window.location.origin + '/payment-success?token=' + this.token,
          failureUrl: window.location.origin + '/payment-failure?token=' + this.token,
          version: '2.0',
          variable1: res.id,
          variable2: res.civilCardId,
          variable3: res.rcBookId,
          variable4: this.translateService.lang,
          variable5: this.renewCustomerId
        };

        const encrypted = this.payment.encryptAes(JSON.stringify(request)); // Ecnryption
        this.tplService.getPaymentGatewayLink(encrypted).subscribe(paymentRes => {
          const decrypted = JSON.parse(this.payment.decryptAes(paymentRes));
          window.location.href = environment.paymetGateway.url + '/payment?data=' + decrypted.response.data;
        }, err => {
          this.snackBox.open( err, "CANCEL", {
            duration: 2000,
            horizontalPosition: "right"
          });
        });
        // });
      }, error => {
        this.snackBox.open( error, "CANCEL", {
          duration: 2000,
          horizontalPosition: "right"
        });
      });
  }

  getSupervisionFeeForPeriod( period: number ): number {
    return ( period == 1 ? this.fee.supervisionFee : ( period == 2 ? ( this.fee.supervisionFee2 ? this.fee.supervisionFee2 : this.fee.supervisionFee ) : ( period == 3 ? ( this.fee.supervisionFee3 ? this.fee.supervisionFee3 : this.fee.supervisionFee ) : this.fee.supervisionFee ) ) );
  }
}
