export class Blog {
    id: string;
    question: string;
    answerHTML: any;
    answerHTMLAr: any;
    userName: string;
    userNameAr : string;
    date: string;
    dateAr: string;
    img: string;
    title: string;
    titleAr: string;
    userImg: string;
    Detail: string;
    twitter: string;
    facebook: string;
}
