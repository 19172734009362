import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AccountService } from 'src/app/account.service';
import { Subject } from 'rxjs';
import { DialogServiceService } from 'src/app/dialog-service.service';
import { DuplicateNumberErrorDialogComponent } from '../duplicate-number-error-dialog/duplicate-number-error-dialog.component';

@Component({
  selector: 'app-phone-number-verification',
  templateUrl: './phone-number-verification.component.html',
  styleUrls: ['./phone-number-verification.component.css']
})
export class PhoneNumberVerificationComponent implements OnInit {

  phoneNumber: string;
  countryCode: string;
  otp: string;
  isOtpSend: boolean = false;
  userId: string;
  duplicateNumberErrorDialog: MatDialogRef<DuplicateNumberErrorDialogComponent>;
  protected _onDestroy = new Subject<void>();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PhoneNumberVerificationComponent>,
    private accountService: AccountService,
    private snackBox: MatSnackBar,
    private dialogService: DialogServiceService) {
      this.userId = data.userId;
    }

  ngOnInit() {
    this.countryCode = '965';

    this.dialogRef.backdropClick().subscribe( backdropClick => {
      this.dialogRef.close({
        userId: this.userId
      });
    });

    document.body.classList.remove( 'og-payment' );
  }

  ngOnDestroy() {
    document.body.classList.remove( 'og-payment' );
    this._onDestroy.next();
    this._onDestroy.complete();
    if ( this.duplicateNumberErrorDialog ) {
      this.duplicateNumberErrorDialog.close();
    }
  }

  verifyOTP(formData): void {
    if ( this.data && this.phoneNumber && this.otp ) {
      const verifyOtpdata = {
        countryCode: this.countryCode,
        phoneNumber: this.phoneNumber,
        otp: this.otp
      }

      this.data.phoneNumber = this.phoneNumber;
      this.data.countryCode = this.countryCode

      this.accountService.verifyOtp( verifyOtpdata ).subscribe( response => {
        this.dialogRef.close({
          success: true,
          phoneNumber: this.data.phoneNumber,
          countryCode: this.data.countryCode,
          token: response.token,
          tplPurchaseId: response.tplPurchaseId,
          civilCardId: response.civilId,
          rcBookId: response.rcBookId,
          userId: this.userId
        });
      }, error => {
        this.showError( error );
      });
    }
  }

  sendOTP(formData): void {
    if ( this.data && this.phoneNumber ) {
      this.data.phoneNumber = this.phoneNumber;
      this.data.countryCode = this.countryCode;
      
      this.accountService.sendOtp( this.data ).subscribe( response => {
        this.isOtpSend = true;
        this.userId = response.userId;
        this.snackBox.open( 'OTP sent successfully', 'CANCEL', {
          duration: 2000,
          horizontalPosition: 'right'
        });
      }, error => {
        if ( error == "You have already purchsed the policy please contact admin" ) {
          this.duplicateNumberErrorDialog = this.dialogService.duplicateNumberErrorDialog( null );
          this.duplicateNumberErrorDialog.afterClosed().subscribe( () => {
            this.duplicateNumberErrorDialog.close();
          }, error => {});
        } else {
          this.showError( error );
        }
      });
    }
  }

  showError(error): void {
    this.snackBox.open( error, 'CANCEL', {
      duration: 2000,
      horizontalPosition: 'right'
    });
  }

}
