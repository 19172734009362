import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GetInTouchService } from '../get-in-touch.service';

@Component({
  selector: 'app-saiyartiAssist',
  templateUrl: './assist.component.html',
  styleUrls: ['./assist.component.css']
})
export class SaiyartiAssistComponent implements OnInit {
  contactSubmitted = false;
  name: string;
  message: string;
  phoneNumber: number;
  email: string;
  @ViewChild('contactForm', { static: true }) contactForm;

  constructor(private titleService: Title, private getInTouchService: GetInTouchService, private router: Router,) { 
    this.titleService.setTitle('Saiyarti-Assist');
  }

  ngOnInit() {
  }

  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.router.navigate(['/termsandcondition' + '/Saiyarti-Assist']);
  }
  
  submitContact(form) {
    const data = {
      email: form.value.email,
      name: form.value.name,
      message: form.value.message,
      phoneNumber: form.value.phoneNumber,
      source: 'Website',
      type: 'Header'
    };
    console.log(form.value);
    this.contactSubmitted = true;
    form.reset();
    setTimeout(() => {
      this.contactSubmitted = false;
    }, 3000);
    this.getInTouchService.getInTouch(data).subscribe(res => { }, err => { });
  }

  scroll(el) {
    document.getElementById(el).scrollIntoView();
  }
}

