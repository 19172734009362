export class Testimonial {
    img: string;
    userName: string;
    userNameAr: string;
    StarImg: string;
    review: string;
    reviewAr: string;
    exeImg: string;
    exeNumber: string;
    exeNumberAr: string;
    exePosition: string;
    exePositionAr: string;
}
