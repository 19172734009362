import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-duplicate-number-error-dialog',
  templateUrl: './duplicate-number-error-dialog.component.html',
  styleUrls: ['./duplicate-number-error-dialog.component.css']
})
export class DuplicateNumberErrorDialogComponent implements OnInit {

  constructor( private dialogRef: MatDialogRef<DuplicateNumberErrorDialogComponent>) { }

  ngOnInit() {
  }

  getDeviceLink(): string {
    if ( !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) ) {
      return "https://bit.ly/3d4E2Wm";
    } else {
      return "https://bit.ly/2YkSjuf";
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
