// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://localhost:5001',
  apiUrl: 'https://saiyarti-dev.azurewebsites.net',
  storageAccount: 'saiyartidev',
  dynamicLink: 'https://saiyartidev.page.link',
  dynamicLinkOfl: 'https://qaportal.saiyarti.com/',
  paymetGateway: {
    url: 'https://sandbox.hesabe.com',
    accessCode: 'c333729b-d060-4b74-a49d-7686a8353481',
    secret: 'PkW64zMe5NVdrlPVNnjo2Jy9nOb7v1Xg',
    ivKey: '5NVdrlPVNnjo2Jy9',
    merchantCode: '842217'
  },
  ogPaymetGateway: {
    url: 'https://pay-it.mobi/globalpayit/pciglobal/WebForms/payitcheckoutservice2.aspx',
    paymentChannel: 'kwknetdctest',
    paymentChannelVisa: 'kwcreditcctest',
    currency: '414',
    merchantName: 'saiyarti',
    authenticationKey: 'GPMw72inibfkqavb',
    originalEn: 'leEDNEZFjC9t32Aa7GN5AFnveJKvX0lEiE5TyK3Ak1w=',
    originalDe: 'XNYH83ZvRJD5NYR8',
    tunnel: 'ogtest',
  },
  apiKey: '27zBLM6ygC6ynAdY',
  apiPassword: 'xz6pcj4VxkRMnbxXB2BheReTmT3m4A7wcSA9qbMk',
  code: ''
};

// export const environment = {
//   production: false,
//   apiUrl: 'https://saiyarti-dev.azurewebsites.net',
//   apiKey: '27zBLM6ygC6ynAdY',
//   apiPassword: 'xz6pcj4VxkRMnbxXB2BheReTmT3m4A7wcSA9qbMk',
//   code: ''
// };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
