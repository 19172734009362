import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-changepassword-success',
  templateUrl: './changepassword-success.component.html',
  styleUrls: ['./changepassword-success.component.css']
})
export class ChangepasswordSuccessComponent implements OnInit {

  name="";
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
   this.name = this.route.snapshot.queryParamMap.get('name');
  }

}
