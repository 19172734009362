import { Component, OnInit, HostListener, ViewChild, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GetInTouchService } from '../get-in-touch.service';
import { TranslateService } from '../translate.service';
declare let $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() hiddenMenu: boolean;
  mTop = '2.1rem';
  Top = '-24px';
  name: string;
  phoneNumber: string;
  lang = 'en';
  contactSubmitted = false;
  isCollapsed = true;
  isnavbarNav = false;
  collapse = true;
  statusClass = 'not-active';
  @ViewChild('contactForm', { static: false }) contactForm;
  @ViewChild('navBar', { static: false }) navBar;
  icon: boolean = false;
  isComprehensive: boolean = false;

  constructor(private getInTouchService: GetInTouchService, private translate: TranslateService, public router: Router, public route: ActivatedRoute) {
    this.setLang(window.localStorage.language);
    let snapshot = route.snapshot;
    if (snapshot.routeConfig.path == "comprehensive") {
      this.isComprehensive = true;
    }
  }

  ngOnInit() {
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (e: any): void => {
    document.getElementById('navbar').style.transition = '.2s';
    document.getElementById('change-lang-en').style.transition = '.2s';
    const element = document.getElementById('navbar');
    const headerLiner = document.getElementById('headerLiner');
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    console.log(offset);
    if (offset > 72) {
      element.classList.add('sticky');
      element.classList.remove('non-sticky');
      document.getElementById('langTag').style.visibility = 'hidden';
      if (headerLiner) {
        headerLiner.style.display = 'none';
      }
      this.mTop = '1.25rem';
    } else {
      element.classList.remove('sticky');
      element.classList.add('non-sticky');
      document.getElementById('langTag').style.visibility = 'visible';
      if (headerLiner) {
        headerLiner.style.display = 'flex';
      }
      this.mTop = '2.1rem';
    }
  }

  setLang(lang: string) {
    this.lang = lang;
    this.translate.use(lang);
    $(document).ready(() => {
      $('body').removeClass('en');
      $('body').removeClass('ar');
      $('body').addClass(lang);
    });
  }
  click() {
    this.icon = !this.icon;
  }

  openContactUs() {
    this.contactSubmitted = false;
    if (this.contactForm) {
      this.contactForm.reset();
    }
  }

  closeNavBar() {
    this.isCollapsed = !this.isCollapsed;
    this.isCollapsed = false;
  }

  submitContact(form) {
    const data = {
      name: form.value.name,
      phoneNumber: '+965 ' + form.value.phoneNumber,
      email: '-',
      message: '-',
      source: 'Website',
      type: 'Header'
    };

    // Regardless of success or error reset the form and show success
    this.contactSubmitted = true;
    form.reset();
    setTimeout(() => {
      this.contactSubmitted = false;
    }, 3000);

    this.getInTouchService.getInTouch(data).subscribe(res => { }, err => { });
  }

  openBusiness() {
    window.location.href = '/business';
  }

  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  openClientPortal() {

    window.open('https://comprehensive.saiyarti.com/customer-login?language='+this.lang , '_blank').focus();

  }
}
