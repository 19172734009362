import { Component, OnInit, ViewChild } from '@angular/core';
import { GetInTouchService } from '../get-in-touch.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  email: string;
  contactName: string;
  message: string;
  phoneNumber: string;
  contactSubmitted = false;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  @ViewChild('contactForm', { static: true }) contactForm;

  constructor(private getInTouchService: GetInTouchService, private snackBar: MatSnackBar, router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.contactForm.reset();
        this.contactSubmitted = false;
      }
    });
  }

  ngOnInit() {

  }

  submitContact(form) {
    const data = {
      email: form.value.email,
      name: form.value.contactName,
      message: form.value.message,
      phoneNumber: form.value.phoneNumber,
      source: 'Website',
      type: 'Contact'
    };
    console.log(form.value);
    this.contactSubmitted = true;
    form.reset();
    setTimeout(() => {
      this.contactSubmitted = false;
    }, 3000);
    this.getInTouchService.getInTouch(data).subscribe(res => { }, err => { });
  }



}
